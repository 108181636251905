/* eslint react/prop-types: 0 */
import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const AuthContext = React.createContext()
export const useAuth = () => useContext(AuthContext)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [client, setClient] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setClient(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const useEffectIsAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(useEffectIsAuthenticated)

      if (useEffectIsAuthenticated) {
        const useEffectUser = await auth0FromHook.getUser()
        setUser(useEffectUser)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const handleRedirectCallback = async () => {
    setLoading(true)
    await client.handleRedirectCallback()
    const callbackUser = await client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(callbackUser)
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        loginWithRedirect: (...p) => client.loginWithRedirect(...p),
        getTokenSilently: (...p) => client.getTokenSilently(...p),
        logout: (...p) => client.logout(...p)
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
