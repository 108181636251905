import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Auth0Provider } from './auth'

import theme from '../theme'
import history from '../utils/history'
import Loading from '../components/Loading'

AppProviders.propTypes = {
  children: PropTypes.element.isRequired
}

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

function AppProviders({ children }) {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
    >
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </ThemeProvider>
    </Auth0Provider>
  )
}

export default AppProviders
