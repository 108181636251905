import React from 'react'
import { useAuth } from './contexts/auth'

const AuthenticatedApp = React.lazy(() => import('./pages/Home'))
const UnauthenticatedApp = React.lazy(() => import('./pages/Login'))

export default function App() {
  const { user } = useAuth()
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}
